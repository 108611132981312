import React, { useState } from 'react';
import Login from './Login';
import SignUp from './SignUp';
import TodoList from './TodoList';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });

  const [userExists, setUserExists] = useState(() => {
    return !!localStorage.getItem('userCredentials');
  });

  const handleSignUp = (username, password) => {
    const userCredentials = { username, password };
    localStorage.setItem('userCredentials', JSON.stringify(userCredentials));
    setUserExists(true);
    alert('Account created! You can now log in.');
  };

  const handleLogin = (username, password) => {
    const storedCredentials = JSON.parse(localStorage.getItem('userCredentials'));
    if (storedCredentials && storedCredentials.username === username && storedCredentials.password === password) {
      setIsLoggedIn(true);
      localStorage.setItem('isLoggedIn', 'true');
    } else {
      alert('Invalid credentials');
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <TodoList onLogout={handleLogout} />
      ) : userExists ? (
        <Login onLogin={handleLogin} />
      ) : (
        <SignUp onSignUp={handleSignUp} />
      )}
    </div>
  );
}

export default App;
